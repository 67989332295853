let defaultStyles = {
	fill: '#00BD9D',
	width: '24px',
	height: '24px',
};

export const PlayCircleIcon = rest => {
	defaultStyles = {
		...defaultStyles,
		...rest,
	};

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...defaultStyles}>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M10 16.5l6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
		</svg>
	);
};
