import { HeadphonesIcon } from 'src/modules/icons/headphones';
import { ComputerIcon } from 'src/modules/icons/computer';
import { DogIcon } from 'src/modules/icons/dog';
import { BathIcon } from 'src/modules/icons/bath';
import { MusicIcon } from 'src/modules/icons/music';
import { SweeperIcon } from 'src/modules/icons/sweeper';
import { PoolIcon } from 'src/modules/icons/pool';
import { PlayCircleIcon } from 'src/modules/icons/play-circle';
import { WatchIcon } from 'src/modules/icons/watch';
import { HangerIcon } from 'src/modules/icons/hanger';

const iconStyles = {
	width: 50,
	height: 50,
};

export const CATEGORIES = [
	{
		name: 'Headphones',
		icon: <HeadphonesIcon {...iconStyles} />,
	},
	{
		name: 'Tablets',
		icon: <ComputerIcon {...iconStyles} />,
	},
	{
		name: "Women's Jewelry",
		icon: <WatchIcon {...iconStyles} />,
	},
	{
		name: 'Dog Food',
		icon: <DogIcon {...iconStyles} />,
	},
	{
		name: 'Bath Towels',
		icon: <BathIcon {...iconStyles} />
	},
	{
		name: "Men's Boxer",
		icon: <HangerIcon {...iconStyles} />,
	},
	{
		name: 'Guitars',
		icon: <MusicIcon {...iconStyles} />,
	},
	{
		name: 'Robotic Vacuums',
		icon: <SweeperIcon {...iconStyles} />,
	},
	{
		name: 'Pool Inflatable',
		icon: <PoolIcon {...iconStyles} />,
	},
	{
		name: 'TV Streamers',
		icon: <PlayCircleIcon {...iconStyles} />,
	},
];

export const TRENDING_CATEGORIES = [
	{
		name: 'Paintings',
		id: '8mpauz'
	},
	{
		name: 'PC Games',
		id: 'x2p2s0',
	},
	{
		name: "Baby Girls' Bodysuits",
		id: '3757gu',
	},
	{
		name: 'Cardio Training',
		id: 'aazrk9'
	},
	{
		name: 'Face Makeup',
		id: '34tnrp',
	},
];
