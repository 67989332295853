import { useEffect, useState } from 'react';
import { EVENTS } from 'src/lib/consts/events';
import { AMAZON, EBAY } from 'src/lib/consts/general';
import { getEntry } from 'src/lib/tools/store/store';
import { pixel, track } from 'src/lib/tools/tracking';
import { isChromeIOS } from 'src/lib/utils/useragent';
import { useZ } from 'src/modules/hooks/useZ';
import styles from './product-cta-button.module.scss';

export const ProductCTAButton = props => {
	const { Element = 'a', className = '', eventSection, customStyles = {}, text = null, position = 0, children, onClick = null } = props;
	let { product } = props;

	const ctaWrapperStyles = customStyles.ctaWrapper || {};
	const ctaStyles = customStyles.cta || {};
	let { clickUrl, vendorId } = product;

	const [finalClickUrl, setClickUrl] = useState(clickUrl);
	const devices = getEntry('devices');
	const cid = useZ('cid');

	useEffect(() => {
		if (vendorId === AMAZON) {
			const tag = (() => {
				let deviceTag = 'dk';
				if (devices.isMobileDevice) {
					deviceTag = 'mob';
				} else if (devices.isTabletDevice) {
					deviceTag = 'tb';
				}
				return `${cid}_${deviceTag}_vother-20`;
			})();

			setClickUrl(finalClickUrl.replace(/(tag=)[^\\&]+/, '$1' + tag));
		}
	}, [cid]);

	const target = isChromeIOS() && vendorId === EBAY ? '_self' : '_blank';

	const renderBuyBtnText = () => {
		if (text) {
			return text;
		}

		return 'Buy now';
	};

	const onBuyClick = e => {
		if (onClick) {
			onClick(e, finalClickUrl);
		} else {
			// window.addEventListener('focus', () => updateM('Feedback on focus', { isFeedback: true }));
			pixel(product);
			track(EVENTS.CLICK.AD_CLICK, {
				productData: product,
				section: eventSection || 'Hero',
				position,
			});
		}
	};

	if (children) {
		return (
			<Element target={target} href={finalClickUrl || clickUrl} rel="noopener" onClick={onBuyClick} className={className}>
				{children}
			</Element>
		);
	}

	return (
		<div className={`${className} ${styles.ctaWrapper}`} style={ctaWrapperStyles}>
			<a className={'cta'} target={target} href={finalClickUrl || clickUrl} rel="noopener" onClick={onBuyClick} style={ctaStyles}>
				{/* {isCartIcon ? <Icon>{buyIcon({ color: 'white' })}</Icon> : null} */}
				<span>{renderBuyBtnText()}</span>
			</a>
		</div>
	);
};
