import { top10UrlBest } from '@/utils/build-url';
import { Link } from '@/components/link/link';
import { CATEGORIES } from '@/consts/categories';
import styles from './categories-boxes.module.scss';
import { EVENTS } from '@/consts/events';
import { trackOnNav } from 'src/lib/tools/tracking';
import { Icon } from '@/components/icon/icon';
import { updateM } from 'src/lib/tools/store/store';

export default function CategoriesBoxes() {
	const renderCategory = (cat, position) => {
		const { name, icon } = cat;
		return (
			<Link
				hrefCB={() => top10UrlBest(name, { q: name })}
				key={name}
				className={styles.topCat}
				onClick={() => {
					updateM('Header Loader', { isHeaderLoader: true });
					trackOnNav(EVENTS.CLICK.POP_CAT, { name, position, section: 'Home Boxes' });
				}}>
				<div>
					<div className={styles.topCatImg}>
						<Icon wrapperStyles={{ justifyContent: 'center', height: '70%' }}>{icon}</Icon>
					</div>
					<div className={styles.topCatName}>{name}</div>
				</div>
			</Link>
		);
	};

	return (
		<div className={styles.topCategories}>
			<section className={styles.topCategoriesSection}>
				<h3 className={styles.sectionTitle}>Popular Lists</h3>
				<div className={styles.sectionContent}>{CATEGORIES.slice(0, 10).map((cat, i) => renderCategory(cat, i))}</div>
			</section>
		</div>
	);
}
