let defaultStyles = {
	fill: '#00BD9D',
	width: '24px',
	height: '24px',
};

export const SweeperIcon = rest => {
	defaultStyles = {
		...defaultStyles,
		...rest,
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			enableBackground="new 0 0 20 20"
			viewBox="0 0 20 20"
			{...defaultStyles}>
			<g>
				<rect fill="none" height="20" width="20" />
			</g>
			<g>
				<g>
					<rect height="5" opacity=".3" width="2" x="9" y="4" />
					<path
						d="M13,10H7c-1.1,0-2,0.9-2,2v4h2v-1.5C7,14.22,7.22,14,7.5,14S8,14.22,8,14.5V16h1.5v-1.5 c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V16H12v-1.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V16h2v-4C15,10.9,14.1,10,13,10z"
						opacity=".3"
					/>
					<path d="M13,9h-1V4c0-0.55-0.45-1-1-1H9C8.45,3,8,3.45,8,4v5H7c-1.66,0-3,1.34-3,3v5c6.51,0,12,0,12,0v-5C16,10.34,14.66,9,13,9z M9,4h2v5H9V4z M15,16h-2v-1.5c0-0.28-0.22-0.5-0.5-0.5S12,14.22,12,14.5V16h-1.5v-1.5c0-0.28-0.22-0.5-0.5-0.5s-0.5,0.22-0.5,0.5 V16H8v-1.5C8,14.22,7.78,14,7.5,14S7,14.22,7,14.5V16H5v-4c0-1.1,0.9-2,2-2h6c1.1,0,2,0.9,2,2V16z" />
				</g>
			</g>
		</svg>
	);
};
